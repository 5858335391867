@import "../../styles/media";

.text {
  h2 {
    @include fadeIn(2s, 0s);
  }
  h1 {
    @include fadeIn(2s, 0.5s);
  }
  p {
    @include fadeIn(2s, 1s);
  }
}

.image {
  @include fadeIn(3s, 0s);
  img {
    filter: brightness(90%);
  }
}