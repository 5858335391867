@mixin desktop {
  @media screen and (min-width: 1000px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin medium-mobile {
  @media screen and (max-width: 750px) {
    @content;
  }
}

@mixin small-mobile {
  @media screen and (max-width: 500px) {
    @content;
  }
}

@mixin fadeIn($duration, $delay) {
  animation-name: fade-in;
  animation-fill-mode: both;
  animation-duration: $duration;
  animation-delay: $delay;

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@mixin fadeOut($duration, $delay) {
  animation-name: fade-out;
  animation-fill-mode: both;
  animation-duration: $duration;
  animation-delay: $delay;

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@mixin custom-scroll-bar() {
  &::-webkit-scrollbar {
    border-radius: 10px;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgb(230, 230, 230);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}