@import "../../styles/variables";
@import "../../styles/media";

.text, .image{
  @include fadeIn(3s, 0s);
}

.text {
  h1{
    text-align: center;
    font-variant: small-caps;
  }
  p {
    font-size: 2.5vmin;
    margin: 20px;
    text-align: start;
  }
}

@include mobile {
  .text {
    p {
      text-align: center;
    }
  }
}

@include desktop {
  .image {
    img {
      max-height: auto;
      width: 10vmin;
    }
  }
}
