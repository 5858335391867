@import "../../styles/variables";
@import "../../styles/media";

.body {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 90%;
}

.text {
  text-align: end;
  margin: auto 0;
}

.image {
  text-align: center;
  margin: auto 0;
  img {
    text-align: end;
  }
}

@include desktop {
  .image {
    img {
      height: auto;
      width: 90%;
    }
  }
}

@include mobile {
  .body {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
  }
  .image {
    grid-row-start: 1;
    grid-row-end: 1;
    img {
      width: 55vmin;
      height: auto;
    }
  }
  .text {
    text-align: center;
  }
}
