@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap");

$websiteBackground: rgb(0, 0, 0);
$navbarItems: rgba(255, 255, 255, 0.699);
$navbarItemsHover: rgb(255, 255, 255);

$cardBorder: rgb(59, 59, 59);
$cardText: rgb(189, 189, 189);
$cardBackgroundHover: rgb(37, 37, 37);
$cardTextHover: rgb(255, 255, 255);

$roboto: "Roboto";
