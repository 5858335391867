@import "../../styles/media";
@import "../../styles/variables";

.skillItem {
  font-size: 30px;
  border: 1px solid white;
  width: max-content;
  padding: 10px;
  border-radius: 4px;
  margin: 10px;
  &:hover{
    cursor: default;
    margin-top: 6px;
    margin-bottom: 14px;
  }
}
