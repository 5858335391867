@import "./styles/variables";
@import "./styles/media";

body {
  margin: 0;
  background-color: $websiteBackground;
  color: white;
  font-family: $roboto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include custom-scroll-bar;
  a {
    text-decoration: none;
    color: white;
  }
  ul {
    list-style: none;
  }
}

h1 {
  font-size: 8vmin;
  margin: 0;
  font-weight: 400;
}

h2 {
  font-size: 4vmin;
  font-weight: 200;
  margin: 0;
}

p {
  font-size: 2vmin;
  margin: 0;
  font-weight: 100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
