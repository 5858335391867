@import "../../styles/media";

.items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @include fadeIn(3s, 0s);
}

@include mobile {
  .items {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include medium-mobile {
  .items {
    grid-template-columns: repeat(1, 1fr);
  }
}
