@import "../../styles/variables";

.link {
  fill: $navbarItems;
  &:hover {
    fill: $navbarItemsHover;
  }
}

.header {
  height: 6rem;
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 15vw 0 15vw;
  .logo {
    text-align: center;
    width: 60px;
  }
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    li {
      display: inline;
      margin-left: 1vw;
      svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}
